import { useContext, useState } from "react";
import { MenuBar } from "./MenuBar";
import { IMenuItem, MenuItem, getNavigation } from "../utils/menuitems";
import { useTranslation } from "react-i18next";
import { AppContext } from "../providers/AppProvider";

export default function Index(props: any) {
  const { t } = useTranslation();
  const app = useContext(AppContext).app;
  const locale = app.language;
  const nightMode = app.nightMode;

  const getMenuItems = () => {
    const getMenuItem = (content: string, isAbstract = false): IMenuItem => {
      return new MenuItem(t("menu." + content), getNavigation((!isAbstract ? content : "content/" + content), locale, nightMode), (!isAbstract ? content : "content/" + content));
    }

    const welcome = `welcome`;
    const vision = `vision`;
    const contact = `contact`;
    const about = `about`;
    const system = `system`;

    let menuItems: IMenuItem[] = [
      getMenuItem(welcome),
      getMenuItem(vision),
      getMenuItem(contact),
      getMenuItem(about)
      //getMenuItem(system)
    ];

    return menuItems;
  };

  const [menu] = useState(getMenuItems());

  return (
    <MenuBar locale={locale} menuItems={menu} />
  )
}
const resources = {
    "weautomateautomation": {
        "softwareanalyst": {
            "name": "Software Analyst",
            "items": [
                "Creëert modellen",
                "Modellen kunnen worden gekoppeld of samengevoegd",
                "Elke element kan worden getagd om het generatieproces te beïnvloeden",
                "Elk element kan in een of meer scopes bestaan: GUI, API, Database"
            ]
        },
        "solutionarchitect": {
            "name": "Solution Architect",
            "items": [
                "Implementeert een applicatie pattern",
                "Creëert architecturen met behulp van packages, mappen, sjablonen en scripts",
                "Elk architectuurelement kan worden getagd",
                "Sjablonen verwerken modellen"
            ]
        },
        "forwardengineer": {
            "name": "Forward Engineer",
            "items": [
                "Genereert een applicatie door een architectuur en een model te selecteren en te combineren",
                "Sjablonen kunnen worden ingesteld om bestanden automatisch te overschrijven",
                "Sjablonen worden voorwaardelijk uitgevoerd met behulp van tags",
                "Externe mappen kunnen voorwaardelijk naar de applicatie worden gekopieerd"
            ]
        },
        "reverseengineer": {
            "name": "Reverse Engineer",
            "items": [
                "Een uitbreidbaar plug-insysteem om een model te importeren of te synchroniseren",
                "SQL Server plug-in"
            ]
        }
    }
}

export default resources;
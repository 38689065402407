import { FC, Suspense, useContext } from "react";
import { Content } from "../buildingblocks/Content";
import { EurekaRole, getItems, getName } from "../buildingblocks/EurekaRole";
import { useTranslation } from "react-i18next";
import { ColorScheduleContext } from "../providers/ColorScheduleProvider";
import { AppContext } from "../providers/AppProvider";

function Index(props: any) {
  const app = useContext(AppContext).app;
  const indent = app.sideBars;
  const locale = app.language;
  
  return (
    <Suspense>
      <Content locale={locale} indent={indent}>
        <SubContent locale={locale} indent={indent}/>
      </Content>
    </Suspense>
  );
}

export default Index;


export const SubContent: FC<any> = (props) => {
  const { locale, indent } = props;

  return (
    <>
      {/* <ContentRole locale={locale} prefix="weautomateautomation.solutionarchitect" content="architecture" />
      <ContentRole locale={locale} prefix="weautomateautomation.softwareanalyst" content="analysis" /> */}
      <ContentRole locale={locale} indent={indent} prefix="weautomateautomation.solutionarchitect" />
      <ContentRole locale={locale} indent={indent} prefix="weautomateautomation.softwareanalyst" />
      <ContentRole locale={locale} indent={indent} prefix="weautomateautomation.forwardengineer" />
      <ContentRole locale={locale} indent={indent} prefix="weautomateautomation.reverseengineer" />
    </>
  )
};

export const ContentRole: FC<any> = (props) => {
  const { locale, prefix, content, indent } = props;
  const { t } = useTranslation(["automation"]);
  const colorSchedule = useContext(ColorScheduleContext).colorSchedule;
  const controlStyle = {
    backgroundColor: colorSchedule.headerColorFrom
  }

  const iconColor = colorSchedule.iconColor;
  const stopColor = colorSchedule.icon2Color;
  const containerStyle = {
    color: colorSchedule.containerTextColor,
    background: `linear-gradient(to bottom,  ${colorSchedule.containerColorFrom} 0%,${colorSchedule.containerColorTo} 100%)`,
    boxShadow: `10px 5px 5px ${colorSchedule.containerShadowColor}`
  }
  const textStyle = {
    color: colorSchedule.containerExplanationColor
  }

  return (
    <>
      <EurekaRole
        prefix={prefix}
        content={content}
        color={iconColor} stopColor={stopColor}
        containerStyle={containerStyle}
        textStyle={textStyle}
        name={getName(prefix, t)}
        items={getItems(prefix, t)}
        locale={locale}
        indent={indent}
      />
    </>
  )
};


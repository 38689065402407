export interface IMenuItem {
    name: string;
    translationKey: string;
    icon?: JSX.Element | undefined;
    url: string;
    enabled?: boolean | true;
}

export class MenuItem implements IMenuItem {
     constructor (public readonly name: string, public readonly url: string, public readonly translationKey: string, public readonly icon?: JSX.Element, public readonly enabled = true) {}
};

export const getNavigation = (content: string, language: string, nightMode: boolean) => {
    return `/${content}?lng=${language}&nightmode=${nightMode}`;
};
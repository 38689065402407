const resources = {
    "content": {
        "content/notfound": {
            "title": "Not found",
            "items": [
                {
                    "title": "",
                    "paragraph": ""
                }
            ]
        },
        "content/exception": {
            "title": "Exception",
            "items": [
                {
                    "title": "",
                    "paragraph": "An exception has occurred. Please contact the administrator of this content."
                }
            ]
        },
        "test": {
            "title": "Test",
            "items": [
                {
                    "title": "",
                    "paragraph": "This is a test."
                }
            ]
        },
        "welcome": {
            "title": "Welcome",
            "items": [
                {
                    "title": "",
                    "paragraph": "Welcome to the Softex website. Softex is a specialist in the field of information systems and builds custom software for the internet. With our years of experience, we help you translate your early idea into the final product and support you with its use."
                },
                {
                    "title": "<goto>\"We automate automation\";automation</goto>",
                    "paragraph": "Our method is that we automate the automation itself. Softex uses automated production lines that can produce software faster and cheaper and in different models and versions. Particularly useful in situations where <italic>time-to-market</italic> is crucial. The rapid and frequent use of lifelike prototypes also makes the operation of the software visible to you at an early stage. These production lines make customization possible."
                },
                {
                    "title": "Contact",
                    "paragraph": "Are you interested in Softex and one of our services? Please <goto>contact</goto> us."
                }
            ]
        },
        "vision": {
            "title": "Vision",
            "items": [
                {
                    "title": "",
                    "paragraph": "Good automation is essential for the efficiency of your company or organization. For this reason, you use standard software, e.g. Office or SAP, that already covers a broad spectrum of your business operations. For the unique and innovative character of your company standard software is not enough, that is why you look for solutions for specific processes in custom software."
                },
                {
                    "title": "",
                    "paragraph": "It may be that the functionality of your current software no longer properly fits. It happens that specifications already change during construction. This could jeopardize the on time usage of the software, its quality and keeping costs within budget. Smaller functional changes can have larger technical and financial consequences. If you are involved in innovation and want to be one step ahead of the competition, the stakes are higher."
                },
                {
                    "title": "Economic climate",
                    "paragraph": "Perhaps you currently have less room for these investments. However, the need for this does not decrease. On the contrary, you now want or need to profile yourself even more, become even more competitive. Your business operations change. Your information needs to change. Your software must change with it."
                },
                {
                    "title": "Solution",
                    "paragraph": "Our solution is cheaper, faster and more efficient software production through <goto>automatic automation;automation</goto> using automated production lines."
                }
            ]
        },
        "contact": {
            "title": "Contact",
            "items": [
                {
                    "title": "",
                    "paragraph": "Complete this form to get in touch:"
                }
            ]
        },
        "contact/sent": {
            "title": "Contact",
            "items": [
                {
                    "title": "",
                    "paragraph": "Your contact info has been sent. Thank you for filling in our form. You will soon hear from us."
                }
            ]
        },
        "contact/notsent": {
            "title": "Contact",
            "items": [
                {
                    "title": "",
                    "paragraph": "Your contact info could not be sent due to an error."
                }
            ]
        },
        "about": {
            "title": "About",
            "items": [
                {
                    "title": "",
                    "paragraph": "This website is (being) developed by Softex with the help of REACT and Remix."
                }
            ]
        },
        "automation": {
            "title": "\"We automate automation\"",
            "items": [
                {
                    "title": "",
                    "paragraph": "Our method is that we automate the automation itself. Softex uses automated production lines, these production lines make customization possible. Within our tools we have the following roles:"
                },
                {
                    "title": "",
                    "paragraph": ""
                }
            ]
        },
        "analysis": {
            "title": "Analysis",
            "items": [
                {
                    "title": "Creates models",
                    "paragraph": "Registering classes, properties, methods, formulas and references/associations between classes. Our core business is the generation of working software modules and applications. We have developed the Reverse Engineer for the import of metadata from other software. <br/>All meta-elements are multilingual, so the templates generate already populated language files: the User Interfaces that we create support multilingualism"
                },
                {
                    "title": "Link or merge models",
                    "paragraph": "References can be made between models, allowing models to be kept small and reused. This means, for example, smaller Apps, but also the development of Micro Services. If desired, models can nevertheless be merged for generation."
                },
                {
                    "title": "Tagged",
                    "paragraph": "Each element can be tagged. These tags can be used by the <goto>Solution Architect;architecture</goto> to set up templates conditionally. The templates use the tags to make various tradeoffs during generation. The design can therefore be separated from the implementation as much as possible. The scopes are an exception to this:"
                },
                {
                    "title": "Scopes: GUI, API, Database",
                    "paragraph": "Nowadays, software often consists of a (separate) user interface (GUI) and/or a registration system (e.g. an RDBMS) and/or internet communication (API, e.g. in the form of WebServices). It can therefore be recorded in which scopes each element exists. In this way we create a bridge from the logical to the physical model. Example:"
                }
            ]
        },
        "architecture": {
            "title": "Architecture",
            "items": [
                {
                    "title": "[TO DO]",
                    "paragraph": ""
                }
            ]
        },
        "system": {
          "title": "System",
          "items": [
            {
              "title": "",
              "paragraph": "System information:"
            }
          ]
        }
    }
}

export default resources;
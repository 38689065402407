import { useContext } from 'react';
import { CompanyIcon } from '../icons/CompanyIcon';
import { CompanyTitleIcon } from '../icons/CompanyTitleIcon';
import { ColorScheduleContext } from '../providers/ColorScheduleProvider';
import { BootstrapLayout } from './BootstrapLayout';
import { useTranslation } from 'react-i18next';
import { getNavigation } from '../utils/menuitems';
import { AppContext } from '../providers/AppProvider';

export const MainHeader = (props: any) => {
    const colorSchedule = useContext(ColorScheduleContext).colorSchedule;

    return (
        <div id="header" style={{
            color: colorSchedule.headerTextColor,
            background: `linear-gradient(to bottom,  ${colorSchedule.headerColorFrom} 0%,${colorSchedule.headerColorTo} 100%)`
        }}>
            <BootstrapLayout>
                <Header />
            </BootstrapLayout>
        </div>
    )
}

export const Header = (props: any) => {
    const { t } = useTranslation();
    const colorSchedule = useContext(ColorScheduleContext).colorSchedule;
    const app = useContext(AppContext).app;
    const locale = app.language;
    const nightMode = app.nightMode;
    const currentYear = new Date().getFullYear();
    const companyName = t("company.name");
    const motto = t("company.motto");
          
    return (
        <div key="header" style={{ backgroundColor: "transparent" }}>
            <div key="table" style={{ display: 'table' }}>
                <div key="tableRow" style={{ display: 'table-row' }}>
                    <div key="tableCellLeft" style={{ display: 'table-cell' }}>
                        <div key="logoIcon" style={{ paddingTop: "0.3em", paddingBottom: "0.3em" }}>
                            <CompanyIcon color={colorSchedule.iconColor} stopColor={colorSchedule.icon2Color} height="5.5em" width="4em" />
                        </div>
                    </div>
                    <div key="tableCellRight" style={{ display: 'table-cell', verticalAlign: "top", float: "none", paddingTop: "0.3em" }} >
                        <div key="subTable" style={{ paddingLeft: "0.6em", display: 'table', verticalAlign: "top" }} >
                            <div key="subTableRowTop" style={{ display: 'table-row', textAlign: "center" }}>
                                <div key="titleIcon" style={{ display: "table-cell" }}>
                                    <CompanyTitleIcon color={colorSchedule.iconColor} height="1.625em" width="12.5em" />
                                </div>
                            </div>
                            <div key="subTableRowBottom" style={{ display: 'table-row', textAlign: "center" }}>
                                <div key="subTableCellBottom" style={{ display: "table-cell" }} >
                                    {/* <div key="motto" style={{ paddingTop: "0.1875em", fontSize: "1.0625em", color: colorSchedule.headerTextColor, cursor: 'default', textAlign: "center", fontWeight: "normal" }}>{motto}</div> */}
                                    <a href={getNavigation("automation", locale, nightMode)}>
                                        <div key="motto" style={{ paddingTop: "0.1875em", fontSize: "1.0625em", color: colorSchedule.headerTextColor, cursor: 'default', textAlign: "center", fontWeight: "normal" }}>{motto}</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const resources = {
    "weautomateautomation": {
        "softwareanalyst": {
            "name": "Software Analyst",
            "items": [
                "Creates data or class models",
                "Models can be linked or merged",
                "Each element can be tagged to influence the generation process",
                "Each element can exist in one or more scopes: GUI, API, Database"
            ]
        },
        "solutionarchitect": {
            "name": "Solution Architect",
            "items": [
                "Implements an application pattern",
                "Creates architectures using packages, folders, templates and scripts",
                "Each architecture-element can be tagged",
                "Templates handle models"
            ]
        },
        "forwardengineer": {
            "name": "Forward Engineer",
            "items": [
                "Generates an application by selecting and combining an architecture and a model",
                "Templates can be set to automatically override files",
                "Templates are conditionally executed using tags",
                "External folders can be copied into the application conditially"
            ]
        },
        "reverseengineer": {
            "name": "Reverse Engineer",
            "items": [
                "A extendable plugin system to import or sync a model",
                "SQL Server plugin"
            ]
        }
    }
}

export default resources;
const resources = {
    "content": {
        "content/notfound": {
            "title": "Nicht gefunden",
            "items": [
                {
                    "title": "",
                    "paragraph": "Ressource existiert nicht."
                }
            ]
        },
        "content/exception": {
            "title": "Ausnahmefehler",
            "items": [
                {
                    "title": "",
                    "paragraph": "Es ist eine Ausnahme aufgetreten. Bitte wenden Sie sich an den Administrator dieses Inhalts."
                }
            ]
        },
        "test": {
            "title": "Test",
            "items": [
                {
                    "title": "",
                    "paragraph": "Das ist ein Test."
                }
            ]
        },
        "welcome": {
            "title": "Willkommen",
            "items": [
                {
                    "title": "",
                    "paragraph": "Willkommen auf der Softex-Website. Softex ist Spezialist im Bereich Informationssysteme und baut kundenspezifische Software unter anderem für das Internet. Mit unserer langjährigen Erfahrung helfen wir Ihnen, Ihre frühe Idee in das Endprodukt umzusetzen und unterstützen Sie bei der Nutzung."
                },
                {
                    "title": "<goto>\"We automate automation\";automation</goto>",
                    "paragraph": "Unsere Methode besteht darin, dass wir die Automatisierung selbst automatisieren. Softex nutzt automatisierte Produktionslinien, die Software schneller und kostengünstiger und in verschiedenen Modellen und Versionen produzieren können. Besonders nützlich in Situationen, in denen <italic>Time-to-Market</italic> entscheidend ist. Durch den schnellen und häufigen Einsatz lebensechter Prototypen wird zudem die Funktionsweise der Software frühzeitig für Sie sichtbar. Diese Produktionslinien ermöglichen individuelle Anpassungen."
                },
                {
                    "title": "Contact",
                    "paragraph": "Sie interessieren sich für Softex und eine unserer Dienstleistungen? Bitte <goto>kontaktieren;contact</goto> Sie uns."
                }
            ]
        },
        "vision": {
            "title": "Vision",
            "items": [
                {
                    "title": "",
                    "paragraph": "Eine gute Automatisierung ist für die Effizienz Ihres Unternehmens oder Ihrer Organisation von entscheidender Bedeutung. Aus diesem Grund nutzen Sie Standardsoftware, z. B. Office oder SAP, die bereits ein breites Spektrum Ihrer Geschäftsabläufe abdeckt. Für den einzigartigen und innovativen Charakter Ihres Unternehmens reicht Standardsoftware nicht aus, Deshalb suchen Sie nach Lösungen für bestimmte Prozesse in kundenspezifischer Software."
                },
                {
                    "title": "",
                    "paragraph": "Es kann sein, dass die Funktionalität Ihrer aktuellen Software nicht mehr richtig passt. Es kommt vor, dass sich Spezifikationen während des Baus ändern. Dies könnte den termin- und budgetgerechten Einsatz der Software oder deren Qualität gefährden. Kleinere funktionale Änderungen können größere technische und finanzielle Folgen haben. Wenn Sie an Innovationen beteiligt sind und der Konkurrenz einen Schritt voraus sein wollen, steht mehr auf dem Spiel."
                },
                {
                    "title": "Wirtschaftslage",
                    "paragraph": "Vielleicht haben Sie derzeit weniger Spielraum für diese Investitionen. Der Bedarf hierfür nimmt jedoch nicht ab. Im Gegenteil, Sie wollen oder müssen sich jetzt noch stärker profilieren, noch wettbewerbsfähiger werden. Ihre Geschäftsabläufe ändern sich. Ihr Informationsbedarf ändert sich. Ihre Software muss sich damit ändern."
                },
                {
                    "title": "Lösung",
                    "paragraph": "Unsere Lösung ist eine kostengünstigere, schnellere und effizientere Softwareproduktion durch <goto>automatische Automatisierung;automation</goto> mithilfe automatisierter Produktionslinien."
                }
            ]
        },
        "contact": {
            "title": "Kontakt",
            "items": [
                {
                    "title": "",
                    "paragraph": "Füllen Sie dieses Formular aus, um Kontakt aufzunehmen:"
                }
            ]
        },
        "contact/sent": {
            "title": "Kontakt",
            "items": [
                {
                    "title": "",
                    "paragraph": "Ihre Kontaktinformationen wurden gesendet. Vielen Dank, dass Sie unser Formular ausgefüllt haben. Sie werden bald von uns hören."
                }
            ]
        },
        "contact/notsent": {
            "title": "Kontakt",
            "items": [
                {
                    "title": "",
                    "paragraph": "Ihre Kontaktinformationen konnten aufgrund eines Fehlers nicht gesendet werden."
                }
            ]
        },
        "about": {
            "title": "Über",
            "items": [
                {
                    "title": "",
                    "paragraph": "Diese Website wird von Softex mit Hilfe von REACT und Remix entwickelt."
                }
            ]
        },
        "automation": {
            "title": "\"We automate automation\"",
            "items": [
                {
                    "title": "",
                    "paragraph": "Unsere Methode besteht darin, dass wir die Automatisierung selbst automatisieren. Softex nutzt automatisierte Produktionslinien, diese Produktionslinien ermöglichen individuelle Anpassungen. Innerhalb unserer Tools haben wir die folgenden Rollen:"
                }
            ]
        },
        "analysis": {
            "title": "Analysis",
            "items": [
                {
                    "title": "Erstellt Modelle",
                    "paragraph": "Registrieren von Klassen, Eigenschaften, Methoden, Formeln und Referenzen/Assoziationen zwischen Klassen. Unser Kerngeschäft ist die Erstellung funktionsfähiger Softwaremodule und Anwendungen. Für den Import von Metadaten aus anderer Software haben wir den Reverse Engineer entwickelt. <br/>Alle Metaelemente sind mehrsprachig, sodass die Schablonen bereits ausgefüllte Sprachdateien generieren: die von uns erstellten User Interfaces unterstützen die Mehrsprachigkeit"
                },
                {
                    "title": "Modelle verknüpfen oder zusammenführen",
                    "paragraph": "Es können Referenzen zwischen Modellen hergestellt werden, sodass Modelle klein gehalten und wiederverwendet werden können. Das bedeutet beispielsweise kleinere Apps, aber auch die Entwicklung von Micro Services. Auf Wunsch können Modelle dennoch zur Generierung zusammengeführt werden."
                },
                {
                    "title": "Mit Tags versehen",
                    "paragraph": "Jedes Element kann mit Tags versehen werden. Diese Tags können vom <goto>Solution Architect;architecture</goto> verwendet werden, um Schablonen bedingt bereitzustellen. Die Schablonen können die Tags verwenden, um während der Generierung andere Überlegungen anzustellen. Der Entwurf kann daher weitestgehend von der Umsetzung getrennt werden. Eine Ausnahme hiervon bilden die Bereiche:"
                },
                {
                    "title": "GUI, API, Datenbank",
                    "paragraph": "Heutzutage besteht Software häufig aus einer (separaten) User Interface (GUI) und/oder einem Registrierungssystem (z. B. einem RDBMS) und/oder einer Internetkommunikation (API, z. B. in Form von WebServices). Somit kann erfasst werden, in welchen Bereichen jedes Element vorhanden ist. Damit schlagen wir eine Brücke vom logischen zum physikalischen Modell."
                }
            ]
        },
        "architecture": {
            "title": "Architektur",
            "items": [
                {
                    "title": "[TO DO]",
                    "paragraph": ""
                }
            ]
        }
    }
}

export default resources;
const resources = {
    "content": {
        "content/notfound": {
            "title": "Niet gevonden",
            "items": [
                {
                    "title": "",
                    "paragraph": "Bron bestaat niet."
                }
            ]
        },
        "content/exception": {
            "title": "Uitzonderingsfout",
            "items": [
                {
                    "title": "",
                    "paragraph": "Er heeft zich een uitzondering voorgedaan. Neem contact op met de beheerder van deze inhoud."
                }
            ]
        },
        "test": {
            "title": "Test",
            "items": [
                {
                    "title": "",
                    "paragraph": "Dit is een test."
                }
            ]
        },
        "welcome": {
            "title": "Welkom",
            "items": [
                {
                    "title": "",
                    "paragraph": "Welkom op de website van Softex. Softex is specialist op het gebied van informatiesystemen en bouwt o.a. maatwerk software voor het internet. Met onze jarenlange ervaring helpen we uw prille idee te vertalen naar het uiteindelijke product en ondersteunen we u bij het gebruik."
                },
                {
                    "title": "<goto>\"We automate automation\";automation</goto>",
                    "paragraph": "Onze methode is dat wij de automatisering zelf automatiseren. Softex maakt gebruik van geautomatiseerde productielijnen die software sneller en goedkoper en in verschillende modellen en uitvoeringen kunnen produceren. Ondermeer handig in situaties waar <italic>time-to-market</italic> cruciaal is. Het snel en veelvuldig inzetten van levensechte prototypes maakt de werking van de software bovendien al in een vroeg stadium voor u zichtbaar. Deze productielijnen maken maatwerk mogelijk."
                },
                {
                    "title": "Contact",
                    "paragraph": "Bent u geïnteresseerd in Softex en een van onze diensten? Neem dan <goto>contact</goto> met ons op."
                }
            ]
        },
        "vision": {
            "title": "Visie",
            "items": [
                {
                    "title": "",
                    "paragraph": "Een goede automatisering is essentieel voor de doelmatigheid van uw bedrjf of organisatie. Om die reden maakt u gebruik van standaard software, bv. Office of SAP, dat reeds een breed spectrum van uw bedrijfsvoering afdekt. Voor het unieke en innoverende karakter van uw bedrijf heeft u niet genoeg aan standaard software, daarom zoekt u voor specifieke processen de oplossing in maatwerksoftware."
                },
                {
                    "title": "",
                    "paragraph": "Het kan zijn dat de functionaliteit van uw huidige software niet meer goed aansluit. Het komt voor dat specificaties nog tijdens de bouw wijzigen. Het op tijd en binnen budget in gebruik nemen van de software of de kwaliteit ervan kan daarmee in gevaar komen. Kleinere functionele wijzigingen kunnen grotere, technische en financiële gevolgen hebben. Als u met innovatie bezig bent, de concurrentie een stap voor wilt zijn, staat er meer op het spel."
                },
                {
                    "title": "Economisch klimaat",
                    "paragraph": "Misschien is er momenteel ook bij u minder ruimte voor deze investeringen. Het is echter niet zo dat de behoefte hieraan afneemt. Integendeel zelfs, u wilt of moet zich nu nog meer profileren, nog competetiever worden. Uw bedrijfsvoering verandert. Uw informatiebehoefte verandert. Uw software moet mee veranderen."
                },
                {
                    "title": "Oplossing",
                    "paragraph": "Onze oplossing is een goedkopere, snellere en efficiëntere softwareproductie door <goto>automatisch automatiseren;automation</goto> met behulp van geautomatiseerde productielijnen."
                }
            ]
        },
        "contact": {
            "title": "Contact",
            "items": [
                {
                    "title": "",
                    "paragraph": "Vul dit formulier in om in contact te komen:"
                }
            ]
        },
        "contact/sent": {
            "title": "Contact",
            "items": [
                {
                    "title": "",
                    "paragraph": "Uw contactgegevens zijn verzonden. Bedankt voor het invullen van ons formulier. U hoort snel van ons."
                }
            ]
        },
        "contact/notsent": {
            "title": "Contact",
            "items": [
                {
                    "title": "",
                    "paragraph": "Uw contactgegevens konden vanwege een fout niet worden verzonden."
                }
            ]
        },
        "about": {
            "title": "Over",
            "items": [
                {
                    "title": "",
                    "paragraph": "Deze website/app is/wordt door Softex ontwikkeld met behulp van REACT en Remix."
                }
            ]
        },
        "automation": {
            "title": "\"We automate automation\"",
            "items": [
                {
                    "title": "",
                    "paragraph": "Onze methode is dat wij de automatisering zelf automatiseren. Softex maakt gebruik van geautomatiseerde productielijnen, deze productielijnen maken maatwerk mogelijk. Binnen onze tools hebben we de volgende rollen:"
                },
                {
                    "title": "",
                    "paragraph": ""
                }
            ]
        },
        "analysis": {
            "title": "Analyse",
            "items": [
                {
                    "title": "Creëert modellen",
                    "paragraph": "Het vastleggen van klasses, eigenschappen, methodes, formules en de referenties/associaties tussen klasses. Onze core-business is de generatie van werkende softwaremodules en -applicaties. Voor de import van metadata uit andere software hebben we de Reverse Engineer ontwikkeld. <br/>Alle meta-elementen zijn meertalig, waardoor de sjablonen reeds gevulde taal-bestanden genereren: de User Interfaces die wij maken ondersteunen meertaligheid "
                },
                {
                    "title": "Modellen koppelen of samenvoegen",
                    "paragraph": "Er kunnen referenties gemaakt worden tussen modellen, waardoor modellen klein gehouden kunnen worden en kunnen worden hergebruikt. Dit betekent bijv. kleinere Apps, maar ook de ontwikkeling van Micro Services. Indien gewenst kunnen modellen niettemin voor generatie worden samengevoegd."
                },
                {
                    "title": "Getagd",
                    "paragraph": "Elk element kan worden getagd. Deze tags kunnen door de <goto>Solution Architect;architecture</goto> worden gebruikt om sjablonen conditioneel in te zetten. De sjablonen zelf gebruiken de tags om verschillende afwegingen te maken tijdens het genereren. Het ontwerp kan daardoor zoveel mogelijk worden losgekoppeld van de implementatie. De scopes zijn hierop een uitzondering:"
                },
                {
                    "title": "Scopes: GUI, API, Database",
                    "paragraph": "Software bestaat tegenwoordig vaak uit een (losse) gebruikersinterface (GUI) en/of een registratiesysteem (bijv. een RDBMS) en/of internetcommunicatie (API bijv. in de vorm van WebServices). Van elke element kan daarom worden vastgelegd in in welke scopes deze bestaat. Op deze manier maken we een brug van het logische naar het fysieke model."
                }
            ]
        },
        "architecture": {
            "title": "Architectuur",
            "items": [
                {
                    "title": "[TO DO]",
                    "paragraph": ""
                }
            ]
        }
    }
}

export default resources;
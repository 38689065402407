import { BrowserRouter, Route, Routes as BrowserRoutes } from 'react-router-dom';
import About from '../pages/About';
import Automation from '../pages/Automation';
import Contact from '../pages/Contact';
import Vision from '../pages/Vision';
import Welcome from '../pages/Welcome';
import NotFound from '../pages/NotFound';
import System from '../pages/System';

export default function Routes(props: any) {
    return (
      <BrowserRouter>
        <BrowserRoutes>
          <Route path="/" element={<Welcome />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/vision" element={<Vision />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/automation" element={<Automation />} />
          <Route path="/system" element={<System />} />
          <Route path="*" element={<NotFound />} />
        </BrowserRoutes>
      </BrowserRouter>
  )
}

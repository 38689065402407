import React, { useReducer } from 'react';
import { MainHeader } from './buildingblocks/Main';
import { colorScheduleReducer, NightColorSchedule, DayLightColorSchedule, ColorScheduleContext } from './providers/ColorScheduleProvider';
import Routes from './routes/ContentRoutes';
import { getQueryParams } from './utils/url';
import { Workspace } from './buildingblocks/Workspace';
import MenuRoutes from './routes/MenuRoutes';
import { AppContext, DEFAULT_APP, appReducer } from './providers/AppProvider';
import { useDeviceSelectors } from 'react-device-detect';
import { getLanguage } from './utils/cultures';

export default function App() {
  const href = window.location.href;
  const [selectors, data] = useDeviceSelectors(window.navigator.userAgent)
  const { isMobileOnly } = selectors

  const [nightMode] = React.useState(
    getQueryParams(href, "nightmode") == "true" || DEFAULT_APP.nightMode
  );
  const language = getQueryParams(href, "lng") || getLanguage();

  const [colorSchedule, dispatch] = useReducer(colorScheduleReducer, nightMode ? NightColorSchedule : DayLightColorSchedule);
  const appValueAction = useReducer(appReducer, DEFAULT_APP);
  const sideBars = DEFAULT_APP.sideBars && (!isMobileOnly);
  const bgColor = sideBars ? colorSchedule.jumbotronColorTo : colorSchedule.surfaceColorTo;
  const key = "href";

  const app = appValueAction[0];
  app.nightMode = nightMode;
  app.sideBars = sideBars;
  app.language = language;

  document.body.style.backgroundColor = bgColor;

  return (
    // <div id="app" style={{ minHeight: "100%" }} key={key}>
    //   <div id="win" style={{ minHeight: "100%" }}>
        <AppContext.Provider
          value={{ app, dispatch: appValueAction[1] }}>
          <ColorScheduleContext.Provider
            value={{ colorSchedule, dispatch }}>
            <MainHeader />
            <MenuRoutes />
            <Workspace sideBars={sideBars}>
              <Routes sideBars={sideBars} />
            </Workspace>
          </ColorScheduleContext.Provider>
        </AppContext.Provider>
    //   </div>
    // </div>
  )
}


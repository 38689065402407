import { FC, useState } from "react";
import { getNavigation } from "../utils/menuitems";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const GotoContent = (props: any) => {
  const { locale, nightMode } = props;
  const [hover, setHover] = useState(false);

  const values = (props.children[0] + "").split(";");
  const name = values[0];
  const content = values[values.length > 1 ? 1 : 0];

  return (
    <a key={"innerLink_" + name}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{
        color: props.linkColor,
        cursor: "pointer",
        textDecoration: hover ? "underline" : "none"
      }}
      href={getNavigation(content, locale, nightMode)}>
      {name}
    </a>
  );
};

export const TooltipLink: FC<any> = (props) => {
  const { url, className, style, active, children, tooltip } = props;

  const tooltipContainer = (
    <Tooltip bsPrefix="tooltip" {...props}>
      <div style={{ backgroundColor: "black" }}>{tooltip}</div>
    </Tooltip>
  );

  return (
    <OverlayTrigger delay={{ show: 100, hide: 100 }}
      trigger={['hover', 'focus']} placement="bottom" overlay={tooltipContainer}>
      <a className={className} href={url}>
        {children}
      </a>
    </OverlayTrigger>
  )
}

export const TooltipContainer: FC<any> = (props) => {
  const { children, tooltip, enabled } = props;


  const tooltipContainer = (
    <Tooltip bsPrefix="tooltip">
      <div style={{ margin: "0px", padding: "0px", backgroundColor: "black" }}>{tooltip}</div>
    </Tooltip>
  );

  // if (!enabled) {
  if (enabled.toString() != 'true') {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <OverlayTrigger delay={{ show: 100, hide: 100 }}
      trigger={['hover', 'focus']} placement="bottom" overlay={tooltipContainer}>
      {children}
    </OverlayTrigger>
  )
}  
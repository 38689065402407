import { Content } from "../buildingblocks/Content";
import ContactForm from "../buildingblocks/ContactForm";
import { ContactProvider } from "../providers/ContactProvider";
import { Suspense, useContext } from "react";
import { AppContext } from "../providers/AppProvider";

function Index(props: any) {
  const app = useContext(AppContext).app;
  const indent = app.sideBars;
  const locale = app.language;
  const storageKey = "ContactInfo";
  const className = "ext-paragraph" + (indent ? "-indent" : "");

  return (
    <Suspense>
      <Content locale={locale} indent={indent}>
        <div className={className}>
          <ContactProvider>
            <ContactForm storageKey={storageKey} />
          </ContactProvider>
        </div>
      </Content>
    </Suspense>
  );
}

export default Index;
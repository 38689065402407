import options from "../i18nextOptions";

export const getLocale = () => {
    return getLanguage();
}

export const getLanguage = () => {
    const langs = options.supportedLngs;
    const langNav = navigator?.language?.split("-")[0];
    if ((langs?.length === 0) || langs?.includes(langNav))
        return langNav;
    return options.fallbackLng;
}

export const getCountry = () => {
    const culture: string = window.navigator.language;
    if (culture) {
        return culture.includes("-") ? culture.split("-")[1] : "";
    } else {
        return "";
    }
}
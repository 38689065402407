import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import i18nextOptions from './i18nextOptions'
import Axios from 'axios';

import automationResourcesEN from './locales/en/automation'; 
import commonResourcesEN from './locales/en/common'; 
import contentResourcesEN from './locales/en/content'; 
import formsResourcesEN from './locales/en/forms'; 
import automationResourcesNL from './locales/nl/automation'; 
import commonResourcesNL from './locales/nl/common'; 
import contentResourcesNL from './locales/nl/content'; 
import formsResourcesNL from './locales/nl/forms'; 
import automationResourcesDE from './locales/de/automation'; 
import commonResourcesDE from './locales/de/common'; 
import contentResourcesDE from './locales/de/content'; 
import formsResourcesDE from './locales/de/forms'; 

function getResources(automationResources: any, commonResources: any, contentResources: any, formsResources: any) {
  const automation = { ...automationResources};
  const common = { ...commonResources};
  const content = { ...contentResources};
  const forms = { ...formsResources};  
  return { automation, common, content, forms};
}

const resources: any = {
  en: getResources(automationResourcesEN, commonResourcesEN, contentResourcesEN, formsResourcesEN),
  de: getResources(automationResourcesDE, commonResourcesDE, contentResourcesDE, formsResourcesDE),
  nl: getResources(automationResourcesNL, commonResourcesNL, contentResourcesNL, formsResourcesNL)
}

console.log(resources);

//const loadPath = 'https://softex.nl/locales/{{lng}}/{{ns}}.json';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  // .use(Backend)
  .init({
    ...i18nextOptions,
    resources,
    ns: ["common", "content", "automation", "forms"],
    // backend: {
    //   loadPath,
    //   parse: (data: any) => {
    //     return data;
    //   },
    //   request: (options: any, url: any, payload: any, callback: any) => {
    //     Axios.get(url)
    //       .then((res) => {
    //         callback(null, res);
    //       })
    //       .catch((err) => {
    //         callback(err, null);
    //       });
    //   },
    // },
});
const resources = {
    "weautomateautomation": {
        "softwareanalyst": {
            "name": "Software Analyst",
            "items": [
                "Erstellt Daten- oder Klassenmodelle",
                "Modelle können verknüpft oder zusammengeführt werden",
                "Jedes Element kann mit Tags versehen werden, um den Generierungsprozess zu beeinflussen",
                "Jedes Element kann in einem oder mehreren Bereichen vorhanden sein: GUI, API, Datenbank"
            ]
        },
        "solutionarchitect": {
            "name": "Solution Architect",
            "items": [
                "Implementiert ein Applikationsmuster",
                "Erstellt Architekturen mithilfe von Pakete, Mappen, Schablonen und Skripts",
                "Jedes Architekturelement kann mit Tags versehen werden",
                "Schablonen verarbeiten Modelle"
            ]
        },
        "forwardengineer": {
            "name": "Forward Engineer",
            "items": [
                "Erstellt eine Applikation durch Auswahl und Kombination einer Architektur und eines Modells",
                "Schablonen können so eingestellt werden, dass Dateien automatisch überschrieben werden",
                "Schablonen werden bedingt mithilfe von Tags ausgeführt",
                "Externe Mappen können bedingt in die Applikation kopiert werden"
            ]
        },
        "reverseengineer": {
            "name": "Reverse Engineer",
            "items": [
                "Ein erweiterbares Plugin-System zum Importieren oder Synchronisieren eines Modells",
                "SQL Server plugin"
            ]
        }
    }
}

export default resources;
import { BrowserRouter, Route, Routes as BrowserRoutes } from 'react-router-dom';
import MenuBarMain from '../buildingblocks/MenuBarMain';
import MenuBarAutomation from '../buildingblocks/MenuBarAutomation';

export default function Routes() {
  return (
    <BrowserRouter>
      <BrowserRoutes> 
        <Route path="/automation" element={<MenuBarAutomation />} />
        <Route path="*" element={<MenuBarMain />} />
      </BrowserRoutes>
    </BrowserRouter>
  )
}

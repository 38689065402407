import { useContext } from "react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { ColorScheduleContext } from "../providers/ColorScheduleProvider";
import { GotoContent } from "./ContentLink";
import { useLocation } from "react-router-dom";
import { getQueryParams } from "../utils/url";

export function Content(props: any) {
  const { t , i18n } = useTranslation("content");
  const { locale, before, children, info, indent } = props;
  const location = useLocation();

  let content = (info != null && info != "") ? info : (location.pathname.substring(1) !== "" ? location.pathname.substring(1) : "welcome") + "";
  const exists = i18n.exists(`content:content.${content}`);
  if (!exists)
      content = "content/notfound";

  const [nightMode] = React.useState(
    getQueryParams(location.search, "nightmode") == "true" || false
  );

  const colorSchedule = useContext(ColorScheduleContext).colorSchedule;
  let contentKey = `content.${content?.toLowerCase()}.items`;
  let mainTitleKey = `content.${content?.toLowerCase()}.title`;

  const components = {
    italic: <i />, bold: <strong />
  };
  let index = -1;

  const [obj] = React.useState(
    t(contentKey, { returnObjects: true })
  )

  if (obj == null || !Array.isArray(obj))
    return (
      <>
      </>);

  const contentBefore = (before) ? <><p className="paragraph"></p>{children}</> : <></>
  const contentAfter = (!before) ? children : <></>

  return (
    <div id="content" style={{ opacity: "100%", background: `linear-gradient(to bottom, ${colorSchedule.surfaceColorFrom} 25%,${colorSchedule.surfaceColorTo} 98%)`, color: colorSchedule.surfaceTextColor, minHeight: "40em" }}>
        <div style={{padding: "1em  ", paddingLeft: (indent ? "1em" : "0em") }}>
        {
          (t(mainTitleKey)) == "" ? (<></>) : (<h4 key={"header_main_title"} style={{ color: colorSchedule.surfaceTitleColor }} className={"main-title" + (indent ? "-indent" : "")}>
            <Trans t={t} key={"main-title"} i18nKey={mainTitleKey} components={
              { goto: <GotoContent locale={locale} nightMode={nightMode} translation={Trans} linkColor={colorSchedule.surfaceTitleLinkColor} />, ...components }}>
            </Trans>
          </h4>)
        }
        {contentBefore}
        <div>
          {
            obj.map((item: any) => {
              index++;
              const section = contentKey + "." + index;
              const titleKey = section + ".title";
              const paragraphKey = section + ".paragraph";
              let title = t(titleKey) == "" ? (<></>) : (<h6 key={section + "_title"}  style={{ color: colorSchedule.surfaceTitleColor }} className={"title" + (indent ? "-indent" : "")}>
                <Trans t={t} key={section + "_trans_title"} i18nKey={titleKey} components={
                  { goto: <GotoContent locale={locale} nightMode={nightMode} translation={Trans} linkColor={colorSchedule.surfaceTitleLinkColor} />, ...components }}>
                </Trans>
              </h6>);
              const paragraph = t(paragraphKey) == "" ? (<></>) : (<p key={section + "_paragraph"} style={{ color: colorSchedule.surfaceTextColor }} className={"paragraph" + (indent ? "-indent" : "")}>
                <Trans t={t} key={section + "_trans_paragraph"} i18nKey={paragraphKey} components={
                  { goto: <GotoContent locale={locale} nightMode={nightMode} translation={Trans} linkColor={colorSchedule.linkColor} />, ...components }}>
                </Trans>
              </p>);
              return (
                <div key={section}>
                  {title}
                  {paragraph}
                </div>
              );
            })}
        </div>
        {contentAfter}
      </div>
    </div>
  )
}


import React, { FC, Suspense, useContext, useEffect, useState } from 'react';
import { ColorScheduleContext } from '../providers/ColorScheduleProvider';
import { IMenuItem, getNavigation } from '../utils/menuitems';
import { Nav, Navbar } from 'react-bootstrap';
import i18nOptions from '../i18nextOptions';
import { SunIcon } from '../icons/SunIcon';
import { MoonIcon } from '../icons/MoonIcon';
import { SwitchIcon } from '../icons/SwitchIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { TooltipLink } from '../buildingblocks/ContentLink';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../providers/AppProvider';

export const Navigator: FC<any> = (props) => {
  const { t } = useTranslation();
  const app = useContext(AppContext).app;
  const locale = app.language;
  const nightMode = app.nightMode;
  const { menuItems } = props;
  const navigate = useNavigate();
  const location = useLocation();

  let currentContent = location.pathname.substring(1);
  if (currentContent == "") {
    currentContent = "welcome";
  }

  const currentLanguage = locale;
  const colorSchedule = useContext(ColorScheduleContext).colorSchedule;
  const [menu] = useState(menuItems);

  const switchUrl = process.env.REACT_APP_SWITCH_URL;

  let [isToggled, setToggled] = useState(false);
  let [isExpanded, setExpanded] = useState(false);
  let [isReady, setReady] = useState(true);

  const getMenuItemColorStyle = (isExpanded: boolean, isEnabled = true) => {
    return {
      color: (isEnabled ? colorSchedule.menuTextColor : colorSchedule.menuDisabledTextColor),
      backgroundColor: isExpanded ? colorSchedule.menuBarColorTo : "transparent",
      borderColor: colorSchedule.activeMenuColor,
      cursor: (isEnabled ? "pointer" : "default"),
    }
  }

  const getClassName = (content: string, isExpanded: boolean, isEnabled = true): string => {
    if (isExpanded && (content === currentContent))
      return "expanded-active-menu-link"
    else if (isExpanded)
      return "expanded-menu-link"
    else if (content === currentContent) {
      return "active-menu-link"
    }
    else
      return "menu-link"
  }

  const toggleColorStyle = { border: "0px", color: colorSchedule.menuToggleLineColor, backgroundColor: colorSchedule.menuToggleColor };

  const setColors = (): void => {
    const mode = !nightMode;
    navigate(getNavigation(currentContent, currentLanguage, mode));
  }

  const ref = React.useRef(null);

  const onToggle = (toggled: boolean) => {
    if (!isReady)
      return;
    setReady(false);
    setToggled(toggled);
    if (toggled) {
      setExpanded(true);
      setReady(true);
    } else {
      const timer = setTimeout(() => {
        setExpanded(false);
        setReady(true);
      }, 350);
      return () => clearTimeout(timer);
    }
  }

  const onClick = (event: any) => {
    // console.log(event.srcElement.className);
    const name = (event.srcElement.className + ""); // shabby
    const selfInflicted = name.includes("navbar-toggler") // shabby // Need a name or an id
    if (isExpanded && !selfInflicted) {
      onToggle(false);
    }
  }

  useEffect(() => {
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isExpanded])

  useEffect(() => {
    const onResize = () => {
      if (isToggled)
        onToggle(false);
    }

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [isToggled])

  let i = 0;
  let delimiter = (<span key={"delim_" + i} />);

  return (
    <Suspense>
      <Navbar expanded={isToggled} ref={ref} onToggle={onToggle} expand="lg" className="navigation-bar">
        <Navbar.Toggle as="div" aria-controls="basic-navbar-nav" style={toggleColorStyle} className="menu-toggle" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav key="myNav">
            {menu.map((menuItem: IMenuItem) => {
              return (
                <Nav.Item key={menuItem.translationKey}>
                  <Nav.Link key={menuItem.translationKey + "_link"} active={false} style={getMenuItemColorStyle(isExpanded, menuItem.enabled)}
                    className={getClassName(menuItem.translationKey, isExpanded)}
                    href={menuItem.enabled ? menuItem.url : ""}>
                    {menuItem.name}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
        </Navbar.Collapse>
        <div style={isExpanded ? { display: "none" } : { display: "block" }} className='language-bar'>
          <div className='language-bar'>
            <TooltipLink tooltip={nightMode ? t("actions.daymode") : t("actions.nightmode")} className="nightmode" url={getNavigation(currentContent, currentLanguage, !nightMode)} style={{}}>
              {nightMode ? (<SunIcon color={colorSchedule.nightModeColor} />) : (<MoonIcon color={colorSchedule.nightModeColor} />)}
            </TooltipLink>
            <TooltipLink tooltip={t("actions.website")} className="switch-app" url={switchUrl + getNavigation(currentContent, currentLanguage, nightMode)} style={{}}>
              {(<SwitchIcon color={colorSchedule.menuTextColor} />)}
            </TooltipLink>
            {i18nOptions.supportedLngs.map((lang: string) => {
              let display = (<></>);
              if (lang === currentLanguage)
                display = (
                  <span style={{ color: colorSchedule.menuTextColor, backgroundColor: "transparent", borderColor: colorSchedule.activeMenuColor }} className='active-language' key={"span_" + lang}>
                    {lang.toUpperCase()}
                  </span>);
              else
                display = (
                  <span className='language' key={"span_" + lang}>
                    <a style={{ color: colorSchedule.menuTextColor }} href={getNavigation(currentContent, lang, nightMode)} className="language">
                      {lang.toUpperCase()}
                    </a>
                  </span>);

              if (i !== 0) {
                delimiter = (<span key={"delim_" + i}>|</span>)
              }
              i++;
              return (
                <span key={"delim_span_" + lang}>{delimiter}{display}</span>
              )
            })}
          </div>
        </div>
      </Navbar>
    </Suspense>
  )
}

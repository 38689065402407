import React, { Context, createContext, useReducer, useEffect, ReactNode } from "react";

export const colorScheduleStorage = "ColorScheduleStorage";
const storage = false;

// Properties involved

export interface IColorSchedule {
  headerColorFrom: string;
  headerColorTo: string;
  headerTextColor: string;
  iconColor: string;
  icon2Color: string;
  menuBarColorFrom: string;
  menuBarColorTo: string;
  menuBarTopColor: string;
  menuBarBottomColor: string;
  menuTextColor: string;
  menuDisabledTextColor: string;
  activeMenuColor: string;
  menuToggleColor: string;
  menuToggleLineColor: string;
  jumbotronColorFrom: string;
  jumbotronColorTo: string;
  surfaceColorFrom: string;
  surfaceColorTo: string;
  surfaceTextColor: string;
  surfaceTitleColor: string;  
  surfaceTitleLinkColor: string;  
  buttonColorFrom: string;
  buttonColorTo: string;
  buttonTextColor: string;
  buttonBorderColor: string;
  buttonDisabledColorFrom: string;
  buttonDisabledColorTo: string;
  buttonDisabledTextColor: string;
  buttonDisabledBorderColor: string;
  editorColor: string;
  editorTextColor: string;
  editorBorderColor: string;
  editorDisabledColor: string;
  editorDisabledTextColor: string;
  editorDisabledBorderColor: string;
  linkColor: string;
  errorColor: string;
  nightModeColor: string;
  containerColorFrom: string;
  containerColorTo: string;
  containerTextColor: string;
  containerShadowColor: string;
  containerExplanationColor: string;
  copyRightColor: string;
}

export const getColors = (schedule: IColorSchedule): string[] => {
  const result: string[] = [];
  result.push(schedule.headerColorFrom);
  result.push(schedule.headerColorTo);
  result.push(schedule.headerTextColor);
  result.push(schedule.iconColor);
  result.push(schedule.icon2Color);
  result.push(schedule.menuBarColorFrom);
  result.push(schedule.menuBarColorTo);
  result.push(schedule.menuBarTopColor);
  result.push(schedule.menuBarBottomColor);
  result.push(schedule.menuTextColor);
  result.push(schedule.menuDisabledTextColor)
  result.push(schedule.activeMenuColor);
  result.push(schedule.menuToggleColor);
  result.push(schedule.menuToggleLineColor);
  result.push(schedule.jumbotronColorFrom);
  result.push(schedule.jumbotronColorTo);
  result.push(schedule.surfaceColorFrom);
  result.push(schedule.surfaceColorTo);
  result.push(schedule.surfaceTextColor);
  result.push(schedule.buttonColorFrom);
  result.push(schedule.buttonColorTo);
  result.push(schedule.buttonTextColor);
  result.push(schedule.buttonBorderColor);
  result.push(schedule.buttonDisabledColorFrom);
  result.push(schedule.buttonDisabledColorTo);
  result.push(schedule.buttonDisabledTextColor);
  result.push(schedule.buttonDisabledBorderColor);
  result.push(schedule.editorColor);
  result.push(schedule.editorTextColor);
  result.push(schedule.editorBorderColor);
  result.push(schedule.linkColor);
  result.push(schedule.editorDisabledColor);
  result.push(schedule.editorDisabledTextColor);
  result.push(schedule.editorDisabledBorderColor);
  result.push(schedule.errorColor);
  result.push(schedule.nightModeColor);
  result.push(schedule.containerColorFrom);
  result.push(schedule.containerColorTo);
  result.push(schedule.containerTextColor);
  result.push(schedule.containerShadowColor);
  result.push(schedule.containerExplanationColor);  
  result.push(schedule.copyRightColor);    

  result.push(schedule.surfaceTitleColor);
  result.push(schedule.surfaceTitleLinkColor);

  return result;
}

export const setColors = (schedule: IColorSchedule, value: string[]): void => {
  schedule.headerColorFrom = value[0];
  schedule.headerColorTo= value[1];
  schedule.headerTextColor = value[2];
  schedule.iconColor = value[3];
  schedule.icon2Color = value[4];
  schedule.menuBarColorFrom = value[5];
  schedule.menuBarColorTo = value[6];
  schedule.menuBarTopColor = value[7];
  schedule.menuBarBottomColor = value[8];
  schedule.menuTextColor = value[9];
  schedule.menuDisabledTextColor = value[10];
  schedule.activeMenuColor = value[11];
  schedule.menuToggleColor = value[12];
  schedule.menuToggleLineColor = value[13];
  schedule.jumbotronColorFrom = value[14];
  schedule.jumbotronColorTo = value[15];
  schedule.surfaceColorFrom = value[16];
  schedule.surfaceColorTo = value[17];
  schedule.surfaceTextColor = value[18];
  schedule.buttonColorFrom = value[19];
  schedule.buttonColorTo = value[20];
  schedule.buttonTextColor = value[21];
  schedule.buttonBorderColor = value[22];
  schedule.buttonColorFrom = value[23];
  schedule.buttonColorTo = value[24];
  schedule.buttonTextColor = value[25];
  schedule.buttonBorderColor = value[26];
  schedule.editorColor = value[27];
  schedule.editorTextColor = value[28];
  schedule.editorBorderColor = value[29];
  schedule.editorDisabledColor = value[30];
  schedule.editorDisabledTextColor = value[31];
  schedule.editorDisabledBorderColor = value[32];
  schedule.linkColor = value[33];
  schedule.errorColor = value[34];
  schedule.nightModeColor = value[35];
  schedule.containerColorFrom = value[36];
  schedule.containerColorTo = value[37];
  schedule.containerTextColor = value[38];
  schedule.containerShadowColor = value[39];
  schedule.containerExplanationColor = value[40];
  schedule.copyRightColor = value[41];

  schedule.surfaceTitleColor = value[42];
  schedule.surfaceTitleLinkColor = value[43];

}; 

export const InitialColorSchedule: IColorSchedule = {
  headerColorFrom: "lightblue",
  headerColorTo: "white",
  headerTextColor: "black",
  iconColor: "rgb(200,0,0)",
  icon2Color: "black",
  menuBarColorFrom: "darkgray",
  menuBarColorTo: "black",
  menuBarTopColor: "lightgray",
  menuBarBottomColor: "black",
  menuTextColor: "white",
  menuDisabledTextColor: "lightgray",
  activeMenuColor: "rgb(200,0,0)",
  menuToggleColor: "gray",
  menuToggleLineColor: "white",
  jumbotronColorFrom: "gray", //"gray",
  jumbotronColorTo: "white",
  surfaceColorFrom:  "white", // #f5f9ff", //"white",
  surfaceColorTo:  "white",
  surfaceTextColor:  "black",
  surfaceTitleColor:  "black",  
  surfaceTitleLinkColor:  "black",  
  buttonColorFrom: "black",
  buttonColorTo: "darkgray",
  buttonTextColor: "white",
  buttonBorderColor: "gray",
  buttonDisabledColorFrom: "black",
  buttonDisabledColorTo: "darkgray",
  buttonDisabledTextColor: "lightgray",
  buttonDisabledBorderColor: "gray",
  editorColor: "white",
  editorTextColor: "black",
  editorBorderColor: "gray",
  editorDisabledColor: "white",
  editorDisabledTextColor: "black",
  editorDisabledBorderColor: "gray",
  linkColor: "rgb(200,0,0)",
  errorColor: "rgb(200,0,0)",
  nightModeColor: "yellow",
  containerColorFrom: "red",
  containerColorTo: "rgb(200,0,0)",
  containerTextColor: "white",
  containerShadowColor: "gray",
  containerExplanationColor: "black",
  copyRightColor: "black"
};

export const DayLightColorSchedule: IColorSchedule = {
    headerColorFrom: "#4691f6", //"black", //#4691f6", //lightblue",
    headerColorTo: "lightblue", //white",
    headerTextColor: "black", //black",
    iconColor: "red", 
    icon2Color: "black",
    menuBarColorFrom: "gray",
    menuBarColorTo: "darkgray",
    menuBarTopColor: "lightgray",
    menuBarBottomColor: "black",
    menuTextColor: "white",
    menuDisabledTextColor: "lightgray",
    activeMenuColor: "red",
    menuToggleColor: "gray",
    menuToggleLineColor: "white",
    jumbotronColorFrom: "lightblue", //gray",
    jumbotronColorTo: "whitesmoke",
    surfaceColorFrom:  "white",
    surfaceColorTo:  "white", //white",
    surfaceTextColor:  "black",
    surfaceTitleColor:  "black",  
    surfaceTitleLinkColor:  "black",    
    
    buttonColorFrom: "lightblue",
    buttonColorTo: "lightblue",
    buttonTextColor: "black",
    buttonBorderColor: "white",
    
    buttonDisabledColorFrom: "lightblue",
    buttonDisabledColorTo: "lightblue",
    buttonDisabledTextColor: "gray",
    buttonDisabledBorderColor: "lightgray",
    
    editorColor: "whitesmoke",
    editorTextColor: "black",
    editorBorderColor: "lightgray",
    editorDisabledColor: "whitesmoke",
    editorDisabledTextColor: "darkgray",
    editorDisabledBorderColor: "gray",
    linkColor: "red",
    errorColor: "red",
    nightModeColor: "yellow",
    containerColorFrom: "#4691f6",
    containerColorTo: "lightblue",
    containerTextColor: "white",
    containerShadowColor: "gray",
    containerExplanationColor: "black",
    copyRightColor: "#202020"
  };

export const NightColorSchedule: IColorSchedule = {
  headerColorFrom: "black",
  headerColorTo: "#0f0f0f",
  headerTextColor: "white",
  iconColor: "rgb(200,0,0)",
  icon2Color: "black",
  menuBarColorFrom: "darkgray",
  menuBarColorTo: "black",
  menuBarTopColor: "lightgray",
  menuBarBottomColor: "darkgray",
  menuTextColor: "white",
  menuDisabledTextColor: "gray",
  activeMenuColor: "rgb(200,0,0)",
  menuToggleColor: "gray",
  menuToggleLineColor: "white",
  jumbotronColorFrom: "darkgray",
  jumbotronColorTo: "black",
  surfaceColorFrom:  "black",
  surfaceColorTo:  "darkred",
  surfaceTextColor:  "lightgray",  
  surfaceTitleColor:  "white",  
  surfaceTitleLinkColor:  "white",  
  
  buttonColorFrom: "orange",
  buttonColorTo: "red",
  buttonTextColor: "lightgray",
  buttonBorderColor: "black",
  
  buttonDisabledColorFrom: "orange",
  buttonDisabledColorTo: "red",
  buttonDisabledTextColor: "gray",
  buttonDisabledBorderColor: "black",
  
  editorColor: "#aaacad",
  editorTextColor: "black",
  editorBorderColor: "black",
  editorDisabledColor: "#aaacad",
  editorDisabledTextColor: "#555555",
  editorDisabledBorderColor: "gray",
  linkColor: "red", //"rgb(200,0,0)",
  errorColor: "rgb(200,0,0)",
  nightModeColor: "yellow",
  containerColorFrom: "rgb(253, 101, 126)", //"#4691f6",
  containerColorTo: "darkred",
  containerTextColor: "lightgray",
  containerShadowColor: "black",
  containerExplanationColor: "lightgray",
  copyRightColor: "lightgray"
};

// Actions/Reducer

export enum ColorScheduleActionType {
  CHANGE_COLORS = 'CHANGE_COLORS'
}

export type ColorScheduleAction = {
  type: ColorScheduleActionType;
  payLoad?: any;
}

export const colorScheduleReducer = (colorSchedule: IColorSchedule, action: ColorScheduleAction): IColorSchedule => {
  const newColorSchedule = {...colorSchedule};
  colorScheduleReducerAction(newColorSchedule, action);
  return newColorSchedule;
}

const colorScheduleReducerAction = (colorSchedule: IColorSchedule, action: ColorScheduleAction) => {
  if (action)
    switch (action.type) {
      case ColorScheduleActionType.CHANGE_COLORS: {
        setColors(colorSchedule, action.payLoad);
        break;
      }
    }
}

// Context and Provider
interface ProviderProps {
  children: ReactNode;
}

export interface IColorScheduleContext {
  colorSchedule: IColorSchedule
  dispatch: React.Dispatch<ColorScheduleAction>;
}

export const ColorScheduleContext: Context<IColorScheduleContext> = createContext(
  {} as IColorScheduleContext
);

export const initState: IColorSchedule =
  (storage && JSON.parse(localStorage.getItem(colorScheduleStorage) as string)) || InitialColorSchedule;

export const ColorScheduleProvider: React.FC<ProviderProps> = ({ children }) => {
  const [colorSchedule, dispatch] = useReducer(colorScheduleReducer, initState);

  useEffect(() => {
    if (storage)
      localStorage.setItem(colorScheduleStorage, JSON.stringify(colorSchedule));
  }, [colorSchedule]);

  return (
    <ColorScheduleContext.Provider
      value={{ colorSchedule, dispatch }}>
        {children}
    </ColorScheduleContext.Provider>
  );
};

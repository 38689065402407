import React, { Suspense, useReducer } from "react";
import { RefObject, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { EmailIcon } from "../icons/EmainIcon";
import { EraseIcon } from "../icons/EraseIcon";
import { ColorScheduleContext } from "../providers/ColorScheduleProvider";
import { ContactActionType, ContactContext, DEFAULT_CONTACT, IContact, Property, contactReducer } from "../providers/ContactProvider";
import { ErrorCode } from "../utils/messages";
import { ObjectValidation, ObjectValidationState } from "../utils/objectvalidation";
import { TooltipContainer } from "./ContentLink";
import { AppContext } from "../providers/AppProvider";

export default function Index(props: any) {
    const { storageKey } = props;

    return (
        <div style={{}}>
            <Content storageKey={storageKey} />
        </div>
    )
}

const initialErrors: Record<string, any> = {};
const initialTouched: Record<string, boolean> = {};

function Content(props: any) {
    const { t } = useTranslation("forms");
    const { i18n } = useTranslation();
    const { storageKey } = props;
    //    const storageKey = undefined;
    const colorSchedule = useContext(ColorScheduleContext).colorSchedule;

    i18n.loadNamespaces("forms");

    const app = useContext(AppContext).app;
    const nightMode = app.nightMode;

    const contactContext = useContext(ContactContext);
    const contact = contactContext.contact;

    const [errors, setErrors] = useState(initialErrors);
    const [touched, setTouched] = useState(initialTouched);

    const canClear = true;
    const canSend = false;

    const buttonColorStyle = (isEnabled = true) => {
        if (isEnabled)
            return (
                {
                    background: `linear-gradient(to bottom,  ${colorSchedule.buttonColorFrom} 0%,${colorSchedule.buttonColorTo} 100%)`,
                    borderColor: colorSchedule.buttonBorderColor,
                    boxShadow: "white"
                }
            )
        else
            return (
                {
                    background: `linear-gradient(to bottom,  ${colorSchedule.buttonDisabledColorFrom} 0%,${colorSchedule.buttonDisabledColorTo} 100%)`,
                    borderColor: colorSchedule.buttonDisabledBorderColor
                }
            )
    }

    const buttonClassName = (isEnabled = true) => {
        if (isEnabled)
            return "button " + ("button-" + (nightMode ? "nightmode" : "daymode"));
        return "button-disabled " + ("button-disabled-" + (nightMode ? "nightmode" : "daymode"));
    }

    const editorColorStyle = (isEnabled = true) => {
        if (isEnabled)
            return (
                {
                    color: colorSchedule.editorTextColor,
                    backgroundColor: colorSchedule.editorColor,
                    borderColor: colorSchedule.editorBorderColor
                }
            )
        else
            return (
                {
                    color: colorSchedule.editorDisabledTextColor,
                    backgroundColor: colorSchedule.editorDisabledColor,
                    borderColor: colorSchedule.editorDisabledBorderColor
                }
            )
    }

    const editorClassName = nightMode ? "form-control editor-nightmode" : "form-control editor";
    const errorColorStyle = {
        color: colorSchedule.errorColor
    };

    const errorSpanList: Record<string, RefObject<HTMLSpanElement>> = {};
    errorSpanList[Property.NAME] = useRef<HTMLSpanElement>(null);
    errorSpanList[Property.EMAIL] = useRef<HTMLSpanElement>(null);
    errorSpanList[Property.MESSAGE] = useRef<HTMLSpanElement>(null);

    const getLocalizedErrorMessage = (errors: Record<string, any>, propertyName: string) => {
        let msg = "";
        if (errors && errors[propertyName]) {
            const errorCode = "forms.contact.errors." + (propertyName + "-" + errors[propertyName]["message"]).toString().toLowerCase();
            msg = t(errorCode);
        }
        return msg;
    }

    const validator = (obj: IContact, propertyName?: string): ObjectValidation => {
        const validator = new ObjectValidation(obj);
        if (propertyName == null || propertyName === Property.NAME)
            validator.get(Property.NAME)
                .validate(validator.notEmpty(Property.NAME), ErrorCode.IsRequired, "Name is required");
        if (propertyName == null || propertyName === Property.EMAIL)
            validator.get(Property.EMAIL)
                .validate(validator.notEmpty(Property.EMAIL), ErrorCode.IsRequired, "E-mail is required")
                .validate(validator.isValidExpression(Property.EMAIL, /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/),
                    ErrorCode.InvalidExpression, "E-mail is an invalid expression");
        if (propertyName == null || propertyName === Property.MESSAGE)
            validator.get(Property.MESSAGE)
                .validate(validator.notEmpty(Property.MESSAGE), ErrorCode.IsRequired, "Message is required");
        return validator;
    }

    const validateProperty = async (contact: IContact, propertyName: string): Promise<void> => {
        const validate = validator(contact);
        const results = await validate.execute(ObjectValidationState.Create, propertyName);
        const refError = errorSpanList[propertyName];
        if (refError && refError.current) {
            refError.current.innerText = getLocalizedErrorMessage(results, propertyName);
            setErrors({ ...errors, [propertyName]: results[propertyName] });
        }
    }

    const onChange = async (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.currentTarget;
        console.log(e);
        (contact as any)[name] = value; // So we don't have to wait ans we can store it sinmediately
        if (storageKey)
            window.localStorage.setItem(storageKey, JSON.stringify(contact));
        contactContext.dispatch([
            { type: ContactActionType.SET_PROPERTY, name, value }
        ]);
        return touched[name] ? validateProperty(contact, name) : undefined;
    };

    const onExit = async (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (clearing) {
            return;
        }

        const { name } = e.currentTarget;
        validateProperty(contact, name);
        setTouched({ ...touched, [name]: true });
    };

    let clearing = false;

    const onMouseDownClearButton = async (e: React.MouseEvent<HTMLButtonElement>) => {
        clearing = true;
    }

    const onFocusClearButton = async (e: React.FormEvent<HTMLButtonElement>) => {
        clearing = false;
    }

    const onClear = async (e: React.FormEvent<HTMLButtonElement>) => {
        if (storageKey)
            window.localStorage.setItem(storageKey, JSON.stringify(DEFAULT_CONTACT));
        contactContext.dispatch([
            { type: ContactActionType.CLEAR }
        ]);
        setErrors(initialErrors);
        setTouched(initialTouched);
    };

    const onSend = async (e: React.FormEvent<HTMLButtonElement>) => {
        const results = await validator(contact).execute(ObjectValidationState.Create);
        if (Object.keys(results).length === 0) {

        } else {
            setErrors(results);
        }
    }

    React.useEffect(() => {
        if (storageKey) {
            const serialized = localStorage.getItem(storageKey);
            if (serialized) {
                const obj = JSON.parse(serialized);
                contactContext.dispatch([
                    { type: ContactActionType.SET_PROPERTY, name: Property.NAME, value: obj[Property.NAME] },
                    { type: ContactActionType.SET_PROPERTY, name: Property.EMAIL, value: obj[Property.EMAIL] },
                    { type: ContactActionType.SET_PROPERTY, name: Property.MESSAGE, value: obj[Property.MESSAGE] }
                ]);
            }
        }
    }, [storageKey]);

    return (
        <form id="contact-form" method="post">
            <Suspense fallback="Loading ...">
                <div className="form">
                    <div className="form-group">
                        <label htmlFor={Property.NAME}>{t("forms.contact.name")}</label>
                        <input type="text" required={true} style={editorColorStyle()} className={editorClassName} aria-describedby="nameHelp"
                            id={Property.NAME} name={Property.NAME} value={contact.name} onChange={onChange} onBlur={onExit} />
                        <span key="nameError" ref={errorSpanList[Property.NAME]} style={errorColorStyle} className="error">
                            {getLocalizedErrorMessage(errors, Property.NAME)}
                        </span>
                    </div>
                    <div className="form-group">
                        <label htmlFor={Property.EMAIL}>{t("forms.contact.email")}</label>
                        <input type="email" style={editorColorStyle()} className={editorClassName} aria-errormessage="emailHelp" aria-describedby="emailHelp"
                            id={Property.EMAIL} name={Property.EMAIL} value={contact.email} onChange={onChange} onBlur={onExit} />
                        <span key="emailError" ref={errorSpanList[Property.EMAIL]} style={errorColorStyle} className="error">
                            {getLocalizedErrorMessage(errors, Property.EMAIL)}
                        </span>
                    </div>
                    <div className="form-group">
                        <label htmlFor={Property.MESSAGE}>{t("forms.contact.message")}</label>
                        <textarea style={editorColorStyle()} className={editorClassName} id={Property.MESSAGE} rows={5} name={Property.MESSAGE} value={contact.message} onChange={onChange} onBlur={onExit} />
                        <span key="messageError" ref={errorSpanList[Property.MESSAGE]} style={errorColorStyle} className="error">
                            {getLocalizedErrorMessage(errors, Property.MESSAGE)}
                        </span>
                    </div>
                    <TooltipContainer enabled={canClear} tooltip={t("forms.contact.actions.clear")}>
                        <button type="button" name="clearButton" onFocus={onFocusClearButton} onMouseDown={onMouseDownClearButton} disabled={!canClear} style={buttonColorStyle()} className={buttonClassName(canClear)} onClick={onClear}>
                            <EraseIcon color={canClear ? colorSchedule.buttonTextColor : colorSchedule.buttonDisabledTextColor} />
                        </button>
                    </TooltipContainer>
                    &nbsp;
                    <TooltipContainer enabled={canSend} tooltip={t("forms.contact.actions.send")}>
                        <button type="button" name="sendButton" disabled={!canSend} style={buttonColorStyle(false)} className={buttonClassName(canSend)}>
                            <EmailIcon color={canSend ? colorSchedule.buttonTextColor : colorSchedule.buttonDisabledTextColor} />
                        </button>
                    </TooltipContainer>
                </div>
            </Suspense>
        </form>)
}
